<template>
  <div>
    <!-- SINGLE PROPERTY-->

    <div
      :style="{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${$api}/uploads/${property.featuredImage}) `,
      }"
      style="background-size: cover; background-position: center"
      class="w-full mx-auto px-4 p-20 h-96"
    >
      <div
        class="
          max-w-6xl
          mx-auto
          container
          text-white
          mt-40
          xl:px-0
          lg:px-16
          md:px-16
          sm:px-20
          px-2
        "
      >
        <span class="text-xs uppercase"> {{ property.location }}</span>
        <h1 class="text-3xl uppercase">{{ property.name }}</h1>
      </div>
    </div>

    <div
      class="
        max-w-4xl
        mx-auto
        container
        leading-7
        text-center
        bg-white
        text-sm text-black
        m-16
        xl:px-0
        lg:px-16
        md:px-16
        sm:px-20
        px-10
      "
      v-html="property.description"
    ></div>

    <div style="max-width: 102rem" class="mx-auto bg-white mt-8 md:mt-18 mb-20">
      <div
        v-for="room in property.roomTypes"
        :key="room._id"
        class="villa-card flex md:flex-row flex-wrap"
      >
        <div
          style="background-color: #002032"
          class="
            villa-card--text-container
            w-full
            md:w-1/2
            bg-white
            text-left
            px-10
            md:px-28
            py-20
            text-gray-200
          "
        >
          <p class="uppercase text-xs text-gray-400 pb-5">
            {{ room.subtitle }}
          </p>
          <h1 class="room-title text-5xl text-left uppercase pb-3">
            {{ room.name }}
          </h1>

          <div class="flex">
            <div class="w-1/5 text-left">
              <p class="text-xs text-gray-500 mt-6">Occupancy:</p>
            </div>
            <div class="w-1/2 text-left">
              <p class="text-xs text-white mt-6">
                <span class="text-gray-400">
                  {{ `${room.maxOccupancy} persons` }}</span
                >
              </p>
            </div>
          </div>
          <div class="flex">
            <div class="w-1/5 text-left">
              <p class="text-xs text-gray-500 mt-2">Room View:</p>
            </div>
            <div class="w-1/2 text-left">
              <p class="text-xs text-gray-500 mt-2">
                <span class="text-gray-400"> {{ room.roomView }}</span>
              </p>
            </div>
          </div>

          <div
            v-html="room.description"
            class="text-xs text-gray-400 mt-7 leading-6"
          ></div>

          <button
            @click="
              $router.push(
                `/enquiry?property=${property._id}&roomtype=${room._id}`
              )
            "
            class="border hover:opacity-80 text-xs uppercase py-4 px-10 mt-7"
          >
            ENQUIRE
          </button>
        </div>
        <div
          class="
            villa-card--image-container
            flex flex-col
            w-full
            md:w-1/2
            bg-white
            text-black
          "
        >
          <!-- slide -->
          <RoomTypeSlider :images="room.slides"></RoomTypeSlider>
          <!-- slide // -->
        </div>
      </div>
    </div>
  </div>

  <StickyBooking />
</template>

<script>
import StickyBooking from "@/components/layout/StickyBooking";
import RoomTypeSlider from "@/components/base/RoomTypeSlider";
export default {
  name: "SingleProperty",

  data() {
    return {
      mainSliderOptions: {
        clones: 3,
        arrows: false,
        autoplay: true,
        // autoHeight: true,
        height: 570,
        interval: 4000,
      },
      property: {},
    };
  },
  methods: {
    async getProperty() {
      try {
        const res = await this.$axios.get(
          `${this.$api_v1}/properties/${this.$route.params.id}`
        );

        if (res.status == 200) {
          this.property = res.data.property;
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  mounted() {
    this.getProperty();
  },
  components: { StickyBooking, RoomTypeSlider },
};
</script>

<style lang="scss" scoped>
.villa-card {
  &:nth-child(even) {
    .villa-card--image-container {
      order: -1;
      @media (max-width: 768px) {
        order: 1;
      }
    }
  }
}

.villa-card--image-container {
  min-height: 500px;
}

.room-title {
  color: #a29c88;
  line-height: 1.2;
}
.room-title::first-line {
  color: #ffffff !important;
}
</style>  

 